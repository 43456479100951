
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { AuthState } from './slice';

const selectDomain = (state: RootState) => {
  return state.auth;
};

export const selectLoading = createSelector(
  [selectDomain],
  (state: AuthState) => state.loading
);

export const selectError = createSelector(
  [selectDomain],
  (state: AuthState) => state.error
);

export const selectAuthUser = createSelector(
  [selectDomain],
  (state: AuthState) => state.authUser
);

export const selectIsAuthen = createSelector(
  [selectDomain],
  (state: AuthState) => state.isAuthenticated
);

export const selectIsQrValid = createSelector(
  [selectDomain],
  (state: AuthState) => state.qrDataRes
);
